import React from 'react';
import BackgroundImage from '../../Assets/bg-image.png';
import './LandingScreen.css'

const LandingPage: React.FC = () => {
    const handleContactClick = () => {
        window.open('mailto:licensing@gvlcomics.com');
    };

    return (
        <div
            style={{
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                className='mainTitle'
            >
               GVL Comics
            </div>
            <div
                className='subTitle'

            >
                Let your content find a new home
            </div>
            <div
                className='footerText'

            >
                For more information, contact us at{' '}
                <span
                    style={{
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }}
                    onClick={handleContactClick}
                >
                    licensing@gvlcomics.com
                </span>
            </div>
        </div>
    );
};

export default LandingPage;
